.bob-frame.contains-c1-bob-form-contact {

    .bob-window {
        margin: 0 auto;

        .bob-header img {
            margin: 10px 0 13px 31px;
        }
    }

    .container-form {
        padding: 0px 30px 30px 30px;
    }



    h3 {
        font-family: $font-family-base;
        font-weight: 400;
        line-height: 34px;
        font-size: 21px
    }

    p {
        font-size: 15px;
    }

    .btn-app {
        color: #fff;

        &:hover,
        &:active,
        &:focus {
            color: #fff;
        }
    }

}

@include media-breakpoint-up(md) {

    .bob-frame.contains-c1-bob-form-contact {

        // .container-form {
        //     padding: 30px;
        // }


        .bob-window {
            width: 600px;
        }
    }
}

