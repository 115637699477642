.footer {

	.footer-column {

		.footer-badges-container {

			.badge {
				width: 100%;
				max-width: 120px;
			}

			.badge-play {
				margin-top: 10px;
			}
		}
	}
}