.jumbotron {

	.row {
		margin: 0;
	}

	.content-headings {
		padding-left: 10%;
		padding-right: 10%;
		position: relative;
	}

	h1 {
		color: #2F2F2F;
		font-weight: 400;
		margin-bottom: 12px;
		margin-top: 24px;
		font-size: 24px;
		line-height: 33px;
	}

	h2 {
		color: #333333;
		font-family: $font-family-goth;
		font-weight: 400;
		font-size: 16px;
		margin-top: -10px;
		// margin-bottom: 140px;
	}

	p.hero-copy {
		margin: 0 0 130px 0;
		color: #333;
		font-size: 16px;
	}

	.content {
		// margin-top: 100px;
		padding: 0;

		.background-top,
		.background-bottom {
			background-image: url('/images/mobile_background.png');
			position: relative;
			background-size: cover;
			height: 140px;
			width: 100%;
		}

		.background-bottom {
			background-position: bottom;
			top: -20px;
		}

		.background-mid {
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#faa75e+0,f68d48+100 */
			background: #faa75e;
			/* Old browsers */
			background: -moz-linear-gradient(top, #faa75e 0%, #f68d48 100%);
			/* FF3.6-15 */
			background: -webkit-linear-gradient(top, #faa75e 0%, #f68d48 100%);
			/* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, #faa75e 0%, #f68d48 100%);
			/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#faa75e', endColorstr='#f68d48', GradientType=0);
			/* IE6-9 */
		}


		.content-mobile {
			display: block;

			h3,
			p {
				color: #fff,
			}


			p {
				max-width: 225px; 
				position: relative;
				display: block;
				top: -35px;
				left: 43px;
			}
		}


		.content-desktop {
			display: none;
		}

		p {
			font-family: $font-family-goth;
			max-width: 300px;
		}

		.content-image-sm {
			width: 150px;
			position: absolute;
			bottom: -250px;
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
			z-index: 10;
		}

		.content-list {
			margin-top: 40px;

			i {
				width: 40px;

				svg {
					fill: #fff;
					width: 25px;
					height: 25px;
				}
			}

		}


		.content-dak-logo,
		.badge img {
			width: 150px;
			margin: 20px auto;
			display: block;
		}

		.content-buttons {
			.btn-app {
				display: none;
			}
		}

		.content-buttons,
		.content-list {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.content-dak-logo {
			position: relative;
			left: 0px;
		}

		h3 {
			margin-top: 20px;
			text-align: center;
		}
	}

	
}

.background-img {
	display: none;
}

@include media-breakpoint-up(sm) {
	.jumbotron {
		.content {
			.content-mobile {
				p {
					max-width: 300px; 
					position: relative;
					display: block;
					top: -35px;
					left: 43px;
				}
			}
		}
	}
}


//@media screen and (min-width: @screen-sm-min) { bootstrap 3
@include media-breakpoint-up(md) {
	.jumbotron {

		h1 {
			font-size: 31px;
			font-weight: bold;
			line-height: 41px;
		}

		h2 {
			font-size: 22px;
			font-weight: normal;
			line-height: 26px;
			margin-bottom: 0;
		}

		h1,
		h2 {
			margin-left: 0;
			margin-right: 0;
		}

		p.hero-copy {
			margin: 30px 0 0 0;
		}

		.content-headings,
		.content-desktop {
			padding-left: 0;
			// margin-left: 70px;
			padding-right: 0;
		}

		.content {
			margin: 50px auto 100px 38%;
			// padding-left: 30px;
			width: 430px;
			position: relative;


			.content-mobile {
				display: none;
			}

			.content-desktop {
				display: block;
				width: 563.203px;
				// padding-left: 7%;
			}

			p {
				color: #333;
				max-width: none;

			}

			.content-image-sm {
				display: block;
				left: -655px;
				width: 182px;
				top: 7px;
			}


			.content-list {

				i {


					svg {
						fill: #F5A74A;
					}
				}

			}

			.content-buttons {
			//	padding-left: 7%;
			//	padding-top: 20px;

				a {
					margin-top: 10px;
				}
			}

			.content-buttons,
			.content-list {
				display: block;
			}



			.badge img {
				width: 135px;
				margin: 0;
			}

			.badge-playstore {
				padding-top: 10px;
			}


			h3 {
				// width: 300px;
				font-size: 20px;
			}

			.content-lowbox {
				margin-top: 81px;
				position: relative;
			}

			h3 {
				text-align: left;
			}

		}

	}

	.content-dak-logo {
		width: 120px;
		height: 105px;
		// margin: 0 auto;
		display: block;
		// position: absolute;
		// left: -145px;
		// top: 556px;
	}

	.content-usp {
		margin-top: 120px;
		color: #fff;

		svg {
			fill: #fff;
			height: 38px;
			width: 38px;
		}

		p {
			position: relative;
			display: block;
			top: -35px;
			left: 43px;
		}
	}

	.content-superhero {
		position: absolute;
		left: -88%;
		bottom: -62px;
		width: 212px;
	}

	.background-img {
		display: block;
		background-image: url('/images/desktop_background.png');
		background-position: 32% 50%;
		background-size: cover;
		width: 100%;
		height: 358px;
		top: 430px;
		position: absolute;
		z-index: -10;
	}
}

@include media-breakpoint-up(lg) {
	.jumbotron {

		.content {
			margin: 70px auto 100px 40%;
			width: 510px;

			.content-image-sm {
				left: -765px;
				// width: 182px;
				top: 7px;
			}
		}
	}


}

@include media-breakpoint-up(xl) {


	.jumbotron {

		.content {
			width: 600px;

			p {
				color: #333;
				max-width: none;
			}

			.content-image-sm {
				left: -850px;
			}

			.content-lowbox {
				margin-left: 0;
			}

		}


		.content-list {

			i {
				svg {
					fill: #F5A74A;
				}
			}

		}

		.content-buttons,
		.content-list {
			display: block;
		}

		.content-superhero {
			display: none;
		}


		.badge img {
			width: 135px;
			margin: 0;
		}

		h3 {
			text-align: left;
		}
	}
}


@include media-breakpoint-up(xxl) {

	.background-img {
		background-image: url('/images/dasktop_background_xl.png');
		background-position: 30% 17%;
		background-size: cover;
		width: 100%;
		// height: 258px;
		position: absolute;

	}
	.content-usp {
		margin-top: 160px;
	}
	
	.jumbotron {
		.content {
			.content-image-sm {
				// top: 55px;
			}
		}
	}
}