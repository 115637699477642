.passages {
  @include space($margin-mobile);
  @include inner-padding($padding-mobile, passage);

  // ********************

  .passage {
    margin-top: 0;
    margin-bottom: 0;

    h2,
    p {
      color: #707070;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      margin: 0 auto;
      max-width: 350px;
    }
  }
}

@include media-breakpoint-up(sm) {
  .passages {
    @include space($margin-tablet);
    @include inner-padding($padding-tablet, passage);
  }
}

@include media-breakpoint-up(md) {
  .passages {
    margin-top: 440px;
    // @include space($margin-desktop);
    @include inner-padding($padding-desktop, passage);
  }
}

@include media-breakpoint-up(lg) {
  .passages {
   // margin-top: 370px;
  }
}