/* set the overriding variables */
$grid-breakpoints: (
	xs: 0,
  sm: 540px,
  md: 768px,
  lg: 992px,
  xl: 1140px,
  xxl: 1400px,
);
$container-max-widths: (
  xs: 0,
  sm: 540px,
  md: 768px,
	lg: 992px,
  xl: 1140px,	
  xxl: 1400px,
);

// custom.less
//$enable-gradients: true;
// ********************
.anchor {
		position: relative;
    top: -100px;
}


.stripe h2, .paragraph h2, .passage h2 {
	font-weight: 400;
}

// button

.btn {
	font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
}

// ********************

// nav icon hamburger menu

.icon-bar.top-bar, .icon-bar.middle-bar, .icon-bar.bottom-bar  {
	background-color: #fff;
}

.navbar-toggler {
	&:focus, &:active {
		outline: none;
    box-shadow: none;
	}
}

.nav-item {
	margin-right: 20px;

	.content-mobile {
		display: block;
	}
}

//@media screen and (min-width: @screen-sm-min) { bootstrap 3
@include media-breakpoint-up(md) {
	.nav-item {
	
		.content-mobile {
			display: none;
		}
	}
}

.buffer-top {
	padding-bottom: 50px;
}

.form-control-description {
	margin-top: 5px;
	opacity: 0.6;
	font-size: 16px;
}